<template>
  <div>
    <h1>Welcome to Nisharga</h1>
    <b-row class="my-2 mx-0">
      <b-col lg="6" offset-lg="3" md="10" offset-md="1">
        <b-form @submit="submit" @reset="onReset" id="signup-form">
          <b-card
            border-variant="light"
            bg-variant="light"
            header="Please fill up the details"
            class="text-center"
          >
            <b-alert :show="error !== ''" variant="danger">{{ error }}</b-alert>
            <b-card-body>
              <b-row class="my-2">
                <b-col md="3" offset-md="1">
                  <label for="input-live">Name:</label>
                </b-col>
                <b-col md="6" offset-md="1">
                  <b-form-input
                    v-model="form.name"
                    placeholder="Enter your full name"
                    required
                    aria-required="true"
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row class="my-2">
                <b-col md="3" offset-md="1">
                  <label for="input-live">Email:</label>
                </b-col>
                <b-col md="6" offset-md="1">
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    placeholder="Enter Email id"
                    required
                    aria-required="true"
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row class="my-2">
                <b-col md="3" offset-md="1">
                  <label for="username-input">UserName:</label>
                </b-col>
                <b-col md="6" offset-md="1">
                  <b-form-input
                    id="username-input"
                    v-model="form.username"
                    placeholder="Please choose one User name"
                    aria-required="true"
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row class="my-2">
                <b-col md="3" offset-md="1">
                  <label for="input-live">Dob:</label>
                </b-col>
                <b-col md="6" offset-md="1">
                  <b-form-datepicker
                    id="date-input"
                    v-model="form.dob"
                    :state="dateRule"
                    :max="maxDate"
                    locale="en-US"
                    required
                    show-decade-nav
                    menu-class="w-100"
                    calendar-width="100%"
                    aria-required="true"
                    aria-controls="date-input"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback id="date-input-feedback">
                    You must be 15+ to register
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row class="my-2">
                <b-col md="3" offset-md="1">
                  <label for="input-live">Gender:</label>
                </b-col>
                <b-col md="6" offset-md="1">
                  <b-form-radio-group
                    id="radio-group-gender"
                    class="radio-alignment-signup"
                    v-model="form.gender"
                    :options="genderOptions"
                    required
                    aria-required="true"
                    name="Gender"
                  ></b-form-radio-group>
                </b-col>
              </b-row>

              <b-row class="my-2">
                <b-col md="3" offset-md="1">
                  <label for="input-live">Mobile:</label>
                </b-col>
                <b-col md="6" offset-md="1">
                  <b-form-input
                    v-model="form.mobile"
                    placeholder="Enter your mobile Number"
                    required
                    aria-required="true"
                  ></b-form-input>
                </b-col>
              </b-row>
              <p class="m-3">
                By creating an account, you agree to our
                <a href="#" @click="showTnCModal = true">Terms & Conditions</a>
              </p>
              <div class="my-4">
                <b-button
                  v-if="!isLoading"
                  type="submit"
                  variant="primary"
                  class="mx-4"
                  >Submit</b-button
                >
                <b-button v-else variant="primary" disabled>
                  <b-spinner small></b-spinner>
                  Please Wait...
                </b-button>
                <b-button type="reset" variant="danger" class="mx-4"
                  >Reset</b-button
                >
              </div>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>
    </b-row>

    <b-modal
      v-model="showTnCModal"
      title="Terms & Condition"
      header-bg-variant="info"
      header-text-variant="default"
      size="xl"
      centered
      no-close-on-backdrop
    >
      <TermsAndConditions style="max-height: 400px; overflow: auto" />
      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-primary"
          @click="showTnCModal = false"
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import moment from "moment/src/moment";
import {
  BAlert,
  BCard,
  BCardBody,
  BForm,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BModal,
  BFormRadioGroup,
  BSpinner,
} from "bootstrap-vue";

export default {
  name: "SignUp",

  metaInfo: {
    title: "Nisharga",
    titleTemplate: "%s | Sign Up | Your way to the world of Nirvana",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Sign up Now! and explore the world of nirvana. Get unlimited content of free Stories | Articles | Blogs | Poems and much more.",
      },
      {
        name: "og:description",
        content:
          "Sign up Now! and explore the world of nirvana. Get unlimited content of free Stories | Articles | Blogs | Poems and much more.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  components: {
    TermsAndConditions: () =>
      import(/* webpackPrefetch: true */ "../components/TermsAndCondition.vue"),
    BAlert,
    BCard,
    BCardBody,
    BForm,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BModal,
    BFormRadioGroup,
    BSpinner,
  },

  data() {
    return {
      form: {
        name: "",
        username: "",
        email: "",
        dob: "",
        mobile: "",
        gender: "",
      },
      error: "",
      showTnCModal: false,
      isLoading: false,
      genderOptions: ["Male", "Female"],
    };
  },

  computed: {
    dateRule() {
      if (!this.form.dob) return false;
      let age = moment().diff(this.form.dob, "years");
      return (
        this.form.dob &&
        /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(this.form.dob) &&
        age >= 15 &&
        age < 100
      );
    },

    maxDate() {
      return moment().subtract(15, "y").format("YYYY-MM-DD");
    },
  },

  methods: {
    ...mapActions(["registerUser", "signOut"]),

    ...mapMutations(["setUserDetails", "setBookmarkList"]),

    async submit(event) {
      event.preventDefault();
      if (!this.dateRule) {
        this.error =
          "Please select correct Date of Birth. You must be 15+ to register";
        return;
      }

      try {
        this.isLoading = true;
        if (!this.form.username) {
          this.form.username = this.form.email;
        }
        let res = await this.registerUser({ form: this.form });

        this.$bvToast.toast(`Registered Successfully`, {
          title: "Success",
          autoHideDelay: 2000,
          variant: `warning`,
          solid: true,
        });

        if (res.token) {
          localStorage.setItem("user", JSON.stringify(res));
        }
        this.setUserDetails(res);
        this.setBookmarkList(res.bookmarks);
        this.$router.push("/home");
      } catch (e) {
        console.error(e);
        this.error = e.response.data.message;
        if (e.response.status === 401) {
          this.signOut();
        }
      } finally {
        this.isLoading = false;
      }
    },

    onReset(event) {
      event.preventDefault();
      this.error = "";
      this.form = {
        name: "",
        username: "",
        email: "",
        dob: "",
        mobile: "",
        gender: "",
      };
    },
  },
};
</script>

<style scoped>
.radio-alignment-signup {
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .radio-alignment-signup {
    text-align: center;
  }
}
</style>
